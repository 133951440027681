const suggestedModules = {
    ModelS: {
        Scandinavian: [
            1641383,
            1641390,
            1641396,
            1641415
        ],
        Modern: [
            1641399,
            1641406,
            1641412,
            1641415
        ],
        Minimalist: [
            1641367,
            1641374,
            1641380,
            1641415
        ]
    },
    ModelM: {
        Scandinavian: [
            1641383,
            1641389,
            1641394,
            1641397,
            1641415
        ],
        Modern: [
            1641399,
            1641405,
            1641410,
            1641413,
            1641415
        ],
        Minimalist: [
            1641367,
            1641373,
            1641378,
            1641381,
            1641415
        ]
    },
    ModelX: {
        Scandinavian: [
            1641384,
            1641391,
            1641390,
            1641396,
            1641397,
            1641415
        ],
        Modern: [
            1641400,
            1641407,
            1641406,
            1641412,
            1641413,
            1641415
        ],
        Minimalist: [
            1641368,
            1641375,
            1641374,
            1641396,
            1641381,
            1641415
        ]
    }
};

const numberKeys = ['zero', 'one', 'two', 'three', 'four', 'five', 'six'];


export const setSuggestedModules = (remoteModules, boatName, moduleSize, interiorName) => {
    let modules = {};
    for (let i = 0; i < moduleSize; i++) {
        modules[numberKeys[i]] = remoteModules.find(module => module.orgamax_id === suggestedModules[boatName][interiorName][i])
    }
    return modules;
}

const suggested = {
    motor: {
        ModelS: 1641418,
        ModelM: 1641418,
        ModelX: 1641418
    },
    battery: {
        ModelS: 1641424,
        ModelM: 1641425,
        ModelX: 1641426
    },
    water: {
        ModelS: 1641427,
        ModelM: 1641428,
        ModelX: 1641429
    },
    treatment: {
        ModelS: 1641430,
        ModelM: 1641430,
        ModelX: 1641430
    },
    waste: {
        ModelS: 2737660,
        ModelM: 2737660,
        ModelX: 1641431
    },
    interior: {
        ModelS: 1641365,
        ModelM: 1641366,
        ModelX: 1641364
    },
    exterior: {
        ModelS: 1641352,
        ModelM: 1641353,
        ModelX: 1641354
    }
}


export const setSuggested = (articles, boatName, which) => {
    return articles.find(module => module.orgamax_id === suggested[which][boatName])
}



