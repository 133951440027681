<template>
  <div class="position-fixed" style="bottom:10px; z-index: 99; width:100%" v-if="!selection.functional||store.state.showGdpr">
    <div class="bg-primary text-white raise-2-primary-50 p-5 m-x-auto w-90p b-gray-light-50 b-1">
      <div class="container m-t-5">
        <h3>Privacy settings</h3>
        <p>Cookies are a necessity of the modern web experience.
          Please have a look at our <router-link class="text-accent" to="/legal/privacy">Privacy Statement</router-link> to understand our approach.</p>
        <div :class="{'grid-5-3-4':toggleManual}">
          <div class="grid p-l-3 place-y-start" v-if="toggleManual">
            <h3 class="m-t-0 m-b-1">Manual Selection</h3>
            <label>
              <input type="checkbox" checked="checked" disabled>
              Functional cookies
            </label>

            <label>
              <input type="checkbox" v-model="selection.orgaMax">
              Third party data processing: <a class="text-accent" href="https://deltra.com">deltra.com</a>
            </label>
            <label>
              <input type="checkbox" v-model="selection.mailjet">
              Third party mailing: <a class="text-accent" href="https://mailjet.com">mailjet.com</a>
            </label>
            <label>
              <input type="checkbox" v-model="selection.analytics">
              Analytics (via <a href="https://analytics.google.com/" class="text-accent">Google Analytics</a>)
            </label>
          </div>
          <div :class="{'grid-4-4-4':!toggleManual}">
            <div class="grid place-y-start">
              <button class="action-button m-b-2 m-r-1" @click="acceptAll">Accept all</button>
            </div>
            <div class="grid place-y-start">
              <button v-if="!toggleManual" class="action-button m-b-2 m-r-1" @click="toggleManual=!toggleManual">Manual selection</button>
              <button v-if="toggleManual" class="action-button m-b-2 m-r-1" @click="acceptSelection">Accept selection</button>
            </div>
            <div class="grid place-y-start">
              <button class="action-button  m-r-1" @click="declineAll">Decline all</button>
            </div>


          </div>

          <div v-if="toggleManual">
            <article class="bg-gray-light-50 p-5" v-if="!selection.mailjet||!selection.orgaMax">
              NOTE: additional grants are required for full functionality, but current settings are sufficient to use this page.
            </article>
          </div>
        </div>
        <hr>
        You can always revisit these settings by clicking on the <i class="las la-user-shield font-md"></i>-icon on the bottom right corner of this page.
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import {useStore} from "vuex";

const store = useStore();




const hasSeen = localStorage.gdpr;

const toggleManual = ref(false)
/* Override: set all to false to activate!*/
localStorage.setItem('gdpr', JSON.stringify({
  functional: true,
  analytics: false,
  orgaMax: true,
  mailjet: true
}))
const selection = ref({
  functional: true,
  analytics: false,
  orgaMax: true,
  mailjet: true
});
if(hasSeen) {
  selection.value = JSON.parse(hasSeen);
}
const save = () => {
  localStorage.setItem('gdpr', JSON.stringify(selection.value))
  store.commit('setGdpr', false)
}

const acceptAll = () => {
  selection.value = {
    functional: true,
    analytics:true,
    orgaMax: true,
    mailjet: true
  }
  save();
}
const declineAll = () => {
  localStorage.removeItem('gdpr');
  selection.value.functional = true;
  store.commit('setGdpr', false)
}

const acceptSelection = () => {
  selection.value.functional = true;
  save();
}

</script>

<style scoped>

</style>