import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import Login from '../views/Login.vue'
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/:preSelected*',
    name: 'Start',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },{
    path: '/exterior',
    name: 'Exterior',
    component: () => import(/* webpackChunkName: "exterior" */ '../views/Exterior.vue'),
    meta: {requiresStore: 'boatModel'}
  },{
    path: '/modules/',
    name: 'Modules',
    component: () => import(/* webpackChunkName: "modules" */ '../views/Modules.vue'),
    meta: {requiresStore: 'interior'}
  },{
    path: '/interior/',
    name: 'Interior',
    component: () => import(/* webpackChunkName: "interior" */ '../views/Interior.vue'),
    meta: {requiresStore: 'exterior'}
  },{
    path: '/driving/',
    name: 'Driving',
    component: () => import(/* webpackChunkName: "driving" */ '../views/Driving.vue'),
  },{
    path: '/supply/',
    name: 'Supply',
    component: () => import(/* webpackChunkName: "supply" */ '../views/Supply.vue'),
    meta: {requiresStore: 'motor'}
  },{
    path: '/add-ons/',
    name: 'AddOns',
    component: () => import(/* webpackChunkName: "addons" */ '../views/AddOns.vue')
  },{
    path: '/checkout/',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue'),
    meta:{requiresStore: 'electricity'}
  },
  {
    path: '/legal/:active',
    name: 'Legal',
    component: () => import(/*  webpackChunkName: "legal"*/ '../views/Legal.vue')
  },
  {
    path: '/stored/:hash',
    name: 'Stored',
    component: () => import(/*  webpackChunkName: "Stored"*/ '../views/Stored.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresStore && !store.state[to.meta.requiresStore].id) {
    return next({path: '/'})

  }
  if ( to.name !== 'Login' && to.name !== 'Stored' && !sessionStorage.hasSession){
    return next({name:'Login'})
  } else {
    return next()
  }
})

export default router
