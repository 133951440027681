<script setup>
import {ref} from "vue";
import api from "@/api";
import {useRouter} from "vue-router"

const router = useRouter();
const credentials = ref({
    username:'',
    password:''
})
const error = ref(false)
const doLogin = async () => {
    try{
        await api.post('/login', credentials.value)
        sessionStorage.hasSession = true
        router.push('/')
    } catch (e) {
        error.value = true;
        sessionStorage.hasSession = false
    }

    console.log(credentials.value)
}
</script>

<template>
    <section>
        <div class="container " style="margin-top: 100px; min-height: 80vh">
            <form @submit.prevent="doLogin">

                <div class="grid">
                    <input v-model="credentials.username" id="username" type="text" name="username" placeholder="username"
                           class="place-x-stretch b-rounded b-1 b-primary p-2 m-y-5">
                    <input v-model="credentials.password" id="password" type="password" name="password" placeholder="password"
                           class="place-x-stretch b-rounded b-1 b-primary p-2 m-y-5">
                    <div v-if="error" class="m-y-5 b-rounded-2 bg-warning text-white p-3">Zugansdaten nicht richtig</div>
                    <div class="place-x-end">
                        <button class="m-y-5 b-rounded b-1 b-gray bg-primary text-white p-x-4 p-y-2" type="submit">Login
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </section>



</template>