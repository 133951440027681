<template>
    <div>
        <gdpr-layer/>
        <div style="z-index: 1; background:white;" v-if="loaded">
            <router-view/>
            <Footer/>
        </div>

        <nav class="bg-transparent  position-absolute" style="z-index: 80; top:0; left:0; right:0;">
            <div class="grid md:grid-6-6">
                <div>
                    <div class="position-absolute" style="left: 10%">
                        <a href="https://laqubina.com" class="text-white text-decoration-none ">
                            <img id="logo" src="@/assets/logo_badge.svg" alt="cubina" style="max-width: 200px;">
                        </a>
                    </div>
                </div>
                <h1 v-if="$store.state.boatModel && $store.state.boatModel.id && $route.name !== 'Modules'"
                    class="position-absolute w-35p m-t-1 p-b-2 d-hidden md:d-block bg-white-50 text-gray"
                    style="text-align: center; z-index:2; left: 30%">
                    {{ $store.state.boatModel.title.split('-')[0] }} -<br>
                    {{ $store.state.boatModel.title.split('-')[1] }}
                </h1>
            </div>
        </nav>
        <div class="grid p-3">

            <div @click="$store.commit('setGdpr', true)"
                 class="place-x-end cursor-pointer b-rounded-3 bg-gray-50 h-25px w-25px" title="privacy settings"
                 style="right: 30px"><i class="las la-user-shield font-md text-white"></i></div>
        </div>
    </div>

</template>

<style lang="scss">
@import "assets/style";

#app {
    min-height: 100vh;
    background-color: #3a4b53;
}
</style>
<script>
import GdprLayer from "@/components/GdprLayer"
import Footer from "@/components/Footer"

export default {
    name: 'App',
    components: {GdprLayer, Footer},
    data:()=>({
        loaded: false,
        boats: [],
        textColor: 'text-gray'
    }),
    watch:{
       '$store.state.boatModel': function(newValue){
           this.textColor = newValue.orgamax_id === 1641349 ? 'text-gray-75' : 'text-gray';
        }
    },
    async mounted() {
        console.log('mounted')
        await this.$store.dispatch('loadAll');
        this.$store.commit('setBoatModel', this.$store.getters.boats[0])
        this.loaded = true;
    }
}
</script>