import {createStore} from "vuex";
import api from "@/api";
import noSolar from "@/assets/solar_no_pure.png";
import singleSolar from "@/assets/solar_single_pure.png";
import doubleSolar from "@/assets/solar_double_pure.png";
import {setSuggested, setSuggestedModules} from "@/store/suggestions";

const calculations = {
    totalSupplyPrice(state) {
        let calc = 0;
        if (typeof state.electricity.price !== 'undefined' && state.electricity.price) {
            calc += state.electricity.price;
        }
        if (typeof state.battery.price !== 'undefined' && state.battery.price) {
            calc += state.battery.price;
        }
        if (typeof state.water.price !== 'undefined' && state.water.price) {
            calc += state.water.price;
        }
        if (typeof state.waste.price !== 'undefined' && state.waste.price) {
            calc += state.waste.price;
        }
        if (typeof state.treatment.price !== 'undefined' && state.treatment.price) {
            calc += state.treatment.price;
        }
        return calc;
    }
}

const store = createStore({
    state() {
        return {
            remoteModules:[],
            articles:[],
            selectedModules: {},
            boatModel: {},
            exterior: {},
            interior: {},
            motor: {},
            electricity: {},
            battery: {},
            water: {},
            treatment:{},
            waste: {},
            addOns: [],
            showGdpr: false
        }
    },
    mutations: {
        setArticles(state, articles) {
            state.articles = articles;
        },
        setRemoteModules(state, modules){
            state.remoteModules = modules;
        },
        setBoatModel(state, boat) {
            const hardCoded = {
                1641349: 'https://laqubina.boats/asset/configurator/01-01-03-Mountain_Closeup.jpg',
                1641350: 'https://laqubina.boats/asset/configurator/01-01-02-Dock_Closeup.jpg',
                1641351: 'https://laqubina.boats/asset/configurator/01-01-01-Horizon_Closeup.jpg'
            };
            const expanded = {...boat, productImage: hardCoded[boat.orgamax_id]}
            state.boatModel = expanded;

            state.motor = setSuggested(state.articles, state.boatModel.boat_name, 'motor')
            state.battery = setSuggested(state.articles, state.boatModel.boat_name, 'battery')
            state.water = setSuggested(state.articles, state.boatModel.boat_name, 'water')
            state.treatment = setSuggested(state.articles, state.boatModel.boat_name, 'treatment')
            state.waste = setSuggested(state.articles, state.boatModel.boat_name, 'waste') || {}
            state.interior = setSuggested(state.articles, state.boatModel.boat_name, 'interior') || {}
            state.exterior = setSuggested(state.articles, state.boatModel.boat_name, 'exterior');
            state.selectedModules = setSuggestedModules(state.articles, state.boatModel.boat_name, state.boatModel.num_modules, state.interior.title);
        },
        setExterior(state, exterior) {
            const hardCoded = {
                'moody brass': 'https://uploads-ssl.webflow.com/61ba0b3fadb67d44a5c5ef4c/61d2288f240e4b21b1a46b45_02-01%20Moody%20brass_web.jpg',
                'natural grade': 'https://uploads-ssl.webflow.com/61ba0b3fadb67d44a5c5ef4c/61d2288f18bc0f128edaa0aa_02-03%20Natural%20grade_web.jpg',
                'white marble': 'https://uploads-ssl.webflow.com/61ba0b3fadb67d44a5c5ef4c/61d2288fba62a58776578ba2_02-02%20White%20marble_web.jpg',
                'night shade':'https://uploads-ssl.webflow.com/61ba0b3fadb67d44a5c5ef4c/61d2288f1145e215d280874a_02-04%20Night%20shade_web.jpg'
            };
            state.exterior = {...exterior, productImage: hardCoded[exterior.title.toLowerCase()]};
        },
        setInterior(state, interior) {
            const interiorImages = {
                'Moody Brass': 'https://uploads-ssl.webflow.com/61ba0b3fadb67d44a5c5ef4c/61d2288f18bc0f128edaa0aa_02-03%20Natural%20grade_web.jpg'
            };
            state.interior = interior;
            state.selectedModules = setSuggestedModules(state.articles, state.boatModel.boat_name, state.boatModel.num_modules, state.interior.title);
        },
        setElectricity(state, accessory) {
            const hardCoded = {
                1641421: noSolar,
                1641422: singleSolar,
                1641423: doubleSolar
            }
            state.electricity = {...accessory, productImage: hardCoded[accessory.orgamax_id]};
        },
        setBattery(state, accessory) {
            state.battery = accessory;
        },
        setWater(state, accessory) {
            state.water = accessory;
        },
        setWaste(state, accessory) {
            state.waste = accessory;
        },
        removeWaste(state, any) {
            state.waste = {}
        },
        setTreatment(state, accessory) {
            state.treatment = accessory;
        },
        removeTreatment(state, any) {
            state.treatment = {}
        },
        setSelectedModules(state, modules) {
            state.selectedModules = modules;
        },
        setMotor(state, motor) {
            state.motor = motor;
        },
        addAddOn(state, addon){
            state.addOns = [...state.addOns,addon]
        },
        removeAddOn(state, addon){
            state.addOns = [...state.addOns.filter(a => a.id !== addon.id)]
        },
        setGdpr(state, bool){
            state.showGdpr = bool;
        }

    },
    actions: {
        loadModules({commit}){
            return new Promise(async resolve => {
                const {data} = await api.get('/cms/Step4')
                commit('setRemoteModules', data)
                resolve('loaded')

            })

        },
        loadAll({commit}){
            return new Promise(async resolve => {
                const {data} = await api.get('/article')
                commit('setArticles', data)
                resolve('loaded')

            })
        }
    },
    getters: {
        boats(state){
          return state.articles.filter(x => x.category === 'Step1.').sort((a,b) => a.num_modules - b.num_modules)
        },
        exterior(state){
            return state.articles.filter(x => x.category === 'Step2.' && x.boat_name === state.boatModel.boat_name)
        },
        interior(state){
            return state.articles.filter(x => x.category === 'Step3.')
        },
        modules(state){
            return interiorName => state.articles.filter(x => {
                return x.category === 'Step4.' && (interiorName === x.interior_style || interiorName === 'Individually' || x.article_module_types.filter(y => y.section === 'balcony').length > 0)
            })
        },
        motors(state){
            return state.articles.filter(x => x.category === 'Step5.')
        },
        supply(state){
            return state.articles.filter(x => x.category === 'Step6.')
        },
        addOns(state){
            return state.articles.filter(x => x.category === 'Step7.')
        },
        totalAddOnPrice(state){
            let p = 0;
            state.addOns.forEach(x => {
                p += x.price;
            })
            return p;
        },
        totalModulePrice(state) {
            let calc = 0;
            Object.keys(state.selectedModules).forEach(x => {
                if (state.selectedModules[x]) {
                    calc += state.selectedModules[x].price;
                }

            })
            return calc;
        },
        totalSupplyPrice(state){
            return calculations.totalSupplyPrice(state);
        },
        totalPrice(state) {
            let calc = state.boatModel.price || 0;
            if (typeof state.exterior.price !== 'undefined') {
                calc += state.exterior.price;
            }
            if (typeof state.interior.price !== 'undefined') {
                calc += state.boatModel.price / 100 * state.interior.price;
            }

            if (typeof state.motor.price !== 'undefined') {
                calc += state.motor.price;
            }
            // total Supply cost
            calc += calculations.totalSupplyPrice(state);

            state.addOns.forEach(addOn => {
                calc += addOn.price;
            })
            Object.keys(state.selectedModules).forEach(x => {
                if (state.selectedModules[x]) {
                    calc += state.selectedModules[x].price;
                }

            })
            return calc;
        },

    }
})
export default store;